export const config = {
  app: {
    name: 'zScrap',
    description: 'zScrap',
    domain: 'zscrap.ai',
    url: 'https://zscrap.ai',
    contact: 'support@l4j3b.com',
  },
  company: {
    name: 'j3b LLC',
    country: 'USA',
    contact: 'contact@l4j3b.com',
    address: '5214F Diamond Heights Blvd #3290, San Francisco, CA 94131',
  },
};
